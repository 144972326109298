// Stylesheets
import './assets/scss/site.scss';

import {fixSvgIntrinsicSizing} from './assets/modules/fixSvgIntrinsicSizing';
import {hover} from './assets/modules/hover';
import {lazyload} from './assets/modules/lazyload';
import {nav} from './assets/modules/nav';
import {initPhotoSwipeFromDOM} from './assets/modules/photoswipe-init';
import {Pjax} from 'pjax-api';

const getOffset = (el) => {
  const box = el.getBoundingClientRect();
  return {
    top: box.top + window.pageYOffset - document.documentElement.clientTop,
    left: box.left + window.pageXOffset - document.documentElement.clientLeft
  }
}

const closest = (el, targetClass) => {
  for (let item = el; item; item = item.parentElement) {
    if (item.classList.contains(targetClass)) {
      return item
    }
  }
}

let removeCurrentOffsetTop = '';

const scrollPos = () => {
  const current = document.querySelector('.js-post-trigger.is-current');
  if(current) {
    const currentOffset = getOffset(current);
    const currentOffsetTop = currentOffset.top;
    const ww = window.document.documentElement.clientWidth;
    if (ww >= 1024) {
      window.scroll(0, currentOffsetTop);
    } else {
      window.scroll(0, currentOffsetTop);
      const header = document.getElementById('identity');
      const headerHeight = header.clientHeight;
      window.scroll(0, currentOffsetTop-headerHeight);
    }
  } else if(removeCurrentOffsetTop) {
    const ww = window.document.documentElement.clientWidth;
    if (ww >= 1024) {
      window.scroll(0, removeCurrentOffsetTop);
    } else {
      window.scroll(0, removeCurrentOffsetTop);
      const header = document.getElementById('identity');
      const headerHeight = header.clientHeight;
      window.scroll(0, removeCurrentOffsetTop-headerHeight);
    }
    removeCurrentOffsetTop = '';
  }
}

const init = () => {
  fixSvgIntrinsicSizing();
  hover();
  lazyload();
  initPhotoSwipeFromDOM('.js-pswp-container');
  scrollPos();
}

nav();
init();

new Pjax({
  areas: [
    '.js-single-content, .js-date, .js-post-trigger, #category-nav',
    '#main, #nav, #category-nav',
    'body'
  ],
  update: {
    css: false,
    script: false
  }
});
 
// document.addEventListener('pjax:content', function(e) {

// });
 
document.addEventListener('pjax:ready', function(e) {
  document.body.classList.remove('is-nav-active');
  init();
  gtag('config', ga_code, {'page_path' : location.pathname});
});