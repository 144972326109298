export function hover() {
  const hov = document.querySelectorAll('a, button, .hov');

  const closest = (el, targetClass) => {
    for (let item = el; item; item = item.parentElement) {
      if (item.classList.contains(targetClass)) {
        return item
      }
    }
  }

  if(IS_TOUCH) {
    for (let i = 0; i < hov.length; i++) {
      const el = hov[i];
      el.addEventListener('touchstart', function(e) {
        this.classList.add('hover');
        const parent = closest(el, 'js-hover-parent');
        if(parent && el.classList.contains('js-hover-parent-trigger') && !el.classList.contains('is-current-trigger')) {
          parent.classList.add('hover-parent');
        }
      });
      el.addEventListener('touchend', function(e) {
        this.classList.remove('hover');
        const parent = closest(el, 'js-hover-parent');
        if(parent) {
          parent.classList.remove('hover-parent');
        }
      });
    };
  } else {
    for (let i = 0; i < hov.length; i++) {
      const el = hov[i];
      el.addEventListener('mouseenter', function(e) {
        this.classList.add('hover');
        const parent = closest(el, 'js-hover-parent');
        if(parent && el.classList.contains('js-hover-parent-trigger') && !el.classList.contains('is-current-trigger')) {
          parent.classList.add('hover-parent');
        }
      });
      el.addEventListener('mouseleave', function(e) {
        this.classList.remove('hover');
        const parent = closest(el, 'js-hover-parent');
        if(parent) {
          parent.classList.remove('hover-parent');
        }
      });
    };
  }
}